import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Auth as AWSAuth } from '@aws-amplify/auth';
import * as Sentry from '@sentry/react';
import toLower from 'lodash/toLower';
import trimStart from 'lodash/trimStart';
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useLazyQuery } from '@apollo/client';
import { Maybe } from 'src/types';
import { LoadingOverlay } from 'src/components/LoadingOverlay';
import { UserAttributesDocument } from 'src/graphql/queries/UserAttributes';
import {
  User,
  Auth,
  Session,
  CognitoAttributes,
  UpdateAttributesInput,
} from './types';
import { normalizeUser } from './normalizeUser';

const FIRST_LOGIN_FLAG = 'FIRST_LOGIN';

const normalizeSession = (session: CognitoUserSession): Session =>
  Object.assign({}, session) as unknown as Session;

const serializePhone = (phone?: string) =>
  phone && `+61${trimStart(phone, '0')}`;

export const AuthContext = createContext<Auth>({} as Auth);
const useAuth = () => useContext(AuthContext);

const useProvideAuth = () => {
  const [user, setUser] = useState<Maybe<User>>(null);
  const [session, setSession] = useState<Maybe<Session>>(null);
  const [isLoading, setLoading] = useState<boolean>(true);

  const [fetchUserAttributes] = useLazyQuery(UserAttributesDocument, {
    fetchPolicy: 'no-cache',
  });

  const clearUser = useCallback(() => {
    setSession(null);
    setUser(null);
  }, [setSession, setUser]);

  const fetchUser = useCallback(async () => {
    let user: User | null = null;
    try {
      const currentUser = await AWSAuth.currentAuthenticatedUser({
        bypassCache: true,
      });
      const currentSession = await AWSAuth.currentSession();
      const userAttributesResult = await fetchUserAttributes({
        context: {
          headers: {
            refresh: currentSession.getRefreshToken().getToken(),
          },
        },
      });
      const graphAttrs = userAttributesResult.data?.viewer?.attributes;
      if (!graphAttrs) {
        throw new Error('Viewer.attributes failed to load');
      }
      user = normalizeUser(graphAttrs, currentUser.attributes);
      setUser(user);
      setSession(normalizeSession(currentSession));
    } catch (error) {
      user = null;
      console.warn(error);
      clearUser();
    } finally {
      setLoading(false);
    }
    return user;
  }, [clearUser, setLoading, setSession, setUser, fetchUserAttributes]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  const signIn = useCallback(
    async (username: string, password: string) => {
      await AWSAuth.signIn(toLower(username), password);
      const user = await fetchUser();
      Sentry.addBreadcrumb({
        category: 'auth',
        message: 'sign in',
        level: 'info',
      });
      Sentry.setUser({ id: user!.uuid });
    },
    [fetchUser],
  );

  const signOut = useCallback(async () => {
    await AWSAuth.signOut();
    clearUser();
    Sentry.addBreadcrumb({
      category: 'auth',
      message: 'sign out',
      level: 'info',
    });
    Sentry.setUser(null);
  }, [clearUser]);

  const updateAttributes = useCallback(
    async (attributes: UpdateAttributesInput) => {
      const cognitoAttributes = Object.entries({
        email: attributes.email,
        family_name: attributes.lastName,
        given_name: attributes.firstName,
        phone_number: serializePhone(attributes.phonePrimary),
        'custom:phone_number_2': serializePhone(attributes.phoneSecondary),
        'custom:position': attributes.position,
      }).reduce((memo, [key, value]) => {
        if (value) {
          memo[key] = value;
        }
        return memo;
      }, {} as Record<string, unknown>);
      const currentUser = await AWSAuth.currentAuthenticatedUser();
      await AWSAuth.updateUserAttributes(currentUser, cognitoAttributes);
      await fetchUser();
    },
    [fetchUser],
  );

  const clearFirstLogin = useCallback<Auth['clearFirstLogin']>(async () => {
    const currentUser: { attributes: CognitoAttributes } =
      await AWSAuth.currentAuthenticatedUser();
    const flags: string[] = JSON.parse(currentUser.attributes['custom:flags']);
    if (flags.includes(FIRST_LOGIN_FLAG)) {
      const updatedFlags = flags.filter((flag) => flag !== FIRST_LOGIN_FLAG);
      await AWSAuth.updateUserAttributes(currentUser, {
        'custom:flags': JSON.stringify(updatedFlags),
      });
      await fetchUser();
    }
  }, [fetchUser]);

  const resetPassword = useCallback(async (email: string) => {
    Sentry.addBreadcrumb({
      category: 'auth',
      message: 'forgot pw',
      level: 'info',
    });
    await AWSAuth.forgotPassword(toLower(email));
  }, []);

  const confirmResetPassword = useCallback(
    async (email: string, code: string, password: string) => {
      Sentry.addBreadcrumb({
        category: 'auth',
        message: 'reset pw',
        level: 'info',
      });
      await AWSAuth.forgotPasswordSubmit(toLower(email), code, password);
      await AWSAuth.signIn(toLower(email), password);
      const currentUser = await AWSAuth.currentAuthenticatedUser();
      await AWSAuth.updateUserAttributes(currentUser, {
        'custom:lastPasswordReset': new Date().toISOString(),
      });
      await signOut();
    },
    [signOut],
  );

  const verifyEmail = useCallback(async () => {
    await AWSAuth.verifyCurrentUserAttribute('email');
  }, []);

  const confirmVerifyEmail = useCallback(async (code: string) => {
    await AWSAuth.verifyCurrentUserAttributeSubmit('email', code);
  }, []);

  const isAuthenticated = Boolean(session);

  return {
    clearFirstLogin,
    user,
    session,
    isAuthenticated,
    isLoading,
    signIn,
    signOut,
    updateAttributes,
    resetPassword,
    confirmResetPassword,
    verifyEmail,
    confirmVerifyEmail,
  };
};

export const AuthProvider: React.FC = ({ children }) => {
  const auth = useProvideAuth();

  if (auth.isLoading) return <LoadingOverlay />;

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const AuthConsumer = AuthContext.Consumer;

export default useAuth;
